import React from 'react';

const InformationWidget = ({
  selectedRoute,
  textOnHover,
  onMouseLeaveText,
}) => (
  <div className='route-info-container'>
    <h4 className='text-sm' style={{ color: '#a01e22', fontSize: '0.875rem' }}>Route Information</h4>
    <div className='flex mt-4'> 
      <div className='route-info-name'>
        <p onClick={(e)=>{textOnHover(e, 'ridge-wollombi')}} onMouseOver={(e) =>{textOnHover(e, 'ridge-wollombi')}} onMouseLeave={(e) =>{onMouseLeaveText(e, 'ridge-wollombi')}} className={`${selectedRoute === 'sw' ? 'font-bold' : ''} cursor-pointer text-left`} > <b>Sydney</b> to Wollombi </p>
        <p onClick={(e)=>{textOnHover(e, 'polkobin-ridge')}} onMouseOver={(e) =>{textOnHover(e, 'polkobin-ridge')}} onMouseLeave={(e) =>{onMouseLeaveText(e, 'polkobin-ridge')}}  className={`${selectedRoute === 'sp' ? 'font-bold' : ''} cursor-pointer text-left` }> <b>Sydney</b> to Pokolbin </p>
        <p onClick={(e)=>{textOnHover(e, 'wollombi-polkobin-direct')}} onMouseOver={(e) =>{textOnHover(e, 'wollombi-polkobin-direct')}} onMouseLeave={(e) =>{onMouseLeaveText(e, 'wollombi-polkobin-direct')}}  className={`${selectedRoute === 'wp' ? 'font-bold' : ''} cursor-pointer text-left`}> <b>Wollombi</b> to Pokolbin </p>
        <p onClick={(e)=>{textOnHover(e, 'wollombi-broke')}} onMouseOver={(e) =>{textOnHover(e, 'wollombi-broke')}} onMouseLeave={(e) =>{onMouseLeaveText(e, 'wollombi-broke')}}  className={`${selectedRoute === 'wb' ? 'font-bold' : ''} cursor-pointer text-left`}> <b>Wollombi</b> to Broke </p>
        <p onClick={(e)=>{textOnHover(e, 'polkobin-newcastle')}} onMouseOver={(e) =>{textOnHover(e, 'polkobin-newcastle')}} onMouseLeave={(e) =>{onMouseLeaveText(e, 'polkobin-newcastle')}}  className={`${selectedRoute === 'wn' ? 'font-bold' : ''} cursor-pointer text-left`}> <b>Wollombi</b> to Newcastle </p>
      </div>
      <div>
        <p className={`${selectedRoute === 'sw' ? 'font-bold' : ''} text-left`}> 104 km (1:25 hrs)</p>
        <p  className={`${selectedRoute === 'sp' ? 'font-bold' : ''} text-left`}> 171 km (1:45 hrs)</p>
        <p  className={`${selectedRoute === 'wp' ? 'font-bold' : ''} text-left`}> 43 km (0:35 hrs)</p>
        <p  className={`${selectedRoute === 'wb' ? 'font-bold' : ''} text-left`}> 30 km (0:20 hrs)</p>
        <p  className={`${selectedRoute === 'wn' ? 'font-bold' : ''} text-left`}> 78km (1:00 hrs)</p>
      </div>
    </div>
  </div>
)

export default React.memo(InformationWidget);
