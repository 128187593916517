import React from 'react';
import TouristMapOnHomepage from './pages/homepage';
import TouristDrive33 from './pages/tourist-drive-33';
import './scss/App.scss';
import './index.css';

const App = () => (
  <div className="App">
    {
      typeof window !== 'undefined' && window.location.pathname === '/tourist-drive-33/'
        ? <TouristDrive33 />
        : <TouristMapOnHomepage />
    }
  </div>
);

export default React.memo(App);
