import React, { useRef, useState } from 'react';
import Map from '../../components/map';
import InformationWidget from '../../components/information';

const TouristDrive33 = () => {
  const svgOnHover = (elem) => {
    if (elem.type === 'click' ) {
      if(selectedRouteID === elem.target.id) {
        setSelectedRouteID('');
      }else{
        setSelectedRouteID(elem.target.id);
      }
    }
    if (elem.target.id === 'polkobin-newcastle'){
      setSelectedRoute('wn');
    }
    else if (elem.target.id === 'ridge-wollombi'){
      setSelectedRoute('sw');
    }
    else if (elem.target.id === 'sydney-ridge'){
      setSelectedRoute('sw');
    }
    else if (elem.target.id === 'polkobin-ridge'){
      setSelectedRoute('sp');
    }
    else if (elem.target.id === 'wollombi-polkobin-direct'){
      setSelectedRoute('wp');
    }
    else if (elem.target.id === 'wollombi-broke'){
      setSelectedRoute('wb');
    }
    else {
      setSelectedRoute('');
    }
  }
  const textOnHover = (elem, id) => {
    if (elem.type === 'click' ) {
      if (selectedRouteID === id) {
        setSelectedRouteID('');
      } else {
        setSelectedRouteID(id);
      }
    }
    if (id === 'polkobin-newcastle') {
      setSelectedRoute('wn');
    }
    else if (id === 'ridge-wollombi') {
      setSelectedRoute('sw');
    }
    else if (id === 'sydney-ridge') {
      setSelectedRoute('sw');
    }
    else if (id === 'polkobin-ridge') {
      setSelectedRoute('sp');
    }
    else if (id === 'wollombi-polkobin-direct') {
      setSelectedRoute('wp');
    }
    else if (id === 'wollombi-broke') {
      setSelectedRoute('wb');
    }
    else {
      setSelectedRoute('');
    }
  }
  const onMouseLeaveText = (e, id) => {
    if (selectedRouteID !== id) {
      setSelectedRoute('');
    }
  }
  const onMouseLeaveSVG = (e) => {
    if (selectedRouteID !== e.target.id) {
      setSelectedRoute('');
    }
  }
  const [selectedRoute, setSelectedRoute] = useState('')
  const [selectedRouteID, setSelectedRouteID] = useState('')
  const [videoUrl, setVideoUrl] = useState('')
  const onClickVideo = (url) => setVideoUrl(url)
  const ref = useRef();

  return (
    <div className='wp-react-app-main'>
    <div className="bg-black w-full h-auto bg-cover	flex items-center ">
      <div className='grid grid-cols-1 w-full h-4/5'>
        {/* <div className='col-span-1 bg-black/50 backdrop-opacity-50 py-4 md:py-0	px-6 lg:px-8 flex flex-col justify-center'>
          {
            typeof window !== 'undefined' && window.location.pathname === '/tourist-drive-33/' && (
              <div>
                <h4 className='text-sm mb-3 mt-6' style={{ color: '#a01e22' }}>Videos</h4>
                <div className='flex'>
                  <div
                    className='w-full mr-3 cursor-pointer'
                    onClick={() => onClickVideo('https://new.wollombi.rapidwebsites.com.au/wp-content/uploads/2022/12/wollombi_part_1.mp4-1080p.mp4')}
                  >
                    <div><img src='https://new.wollombi.rapidwebsites.com.au/wp-content/uploads/2022/12/Wollombi-part-1-clip.jpeg' /></div>
                    <div>Wollombi (part 1)</div>
                  </div>
                  <div
                    className='w-full mr-3 cursor-pointer'
                    onClick={() => onClickVideo('https://new.wollombi.rapidwebsites.com.au/wp-content/uploads/2022/12/wollombi_part_2.mp4-720p.mp4')}
                  >
                    <div><img src='https://new.wollombi.rapidwebsites.com.au/wp-content/uploads/2022/12/Wollombi-part-2-clip.jpeg' /></div>
                    <div>Wollombi (part 2)</div>
                  </div>
                  <div
                    className='w-full mr-3 cursor-pointer'
                    onClick={() => onClickVideo('https://new.wollombi.rapidwebsites.com.au/wp-content/uploads/2022/12/finchley_edit_15_sec_v2-1080p.mp4')}
                  >
                    <div><img src='https://new.wollombi.rapidwebsites.com.au/wp-content/uploads/2022/12/Wollombi-fantastic-clip.jpeg' /></div>
                    <div>Finchley</div>
                  </div>
                </div>
              </div>
            )
          }
        </div> */}
        <div  className='col-span-1 py-4 md:py-0 bg-black/50 backdrop-opacity-50'>
          {/* <InteractiveMap /> */}
          <Map
            selectedRoute={selectedRoute}
            onMouseLeaveSVG={onMouseLeaveSVG}
            svgOnHover={svgOnHover}
          />
          <InformationWidget
            selectedRoute={selectedRoute}
            onMouseLeaveText={onMouseLeaveText}
            textOnHover={textOnHover}
          />
        </div>
      </div>
    </div>
    {/* {
      videoUrl && 
        <div
          ref={ref}
          style={{
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgb(0 0 0 / 83%)',
            zIndex: 99999,
          }}
        >
          <div className='relative'>
            <div
              style={{
                position: 'fixed',
                top: 50,
                right: 50,
                zIndex: 99999,
              }}
              className='cursor-pointer'
              onClick={() => setVideoUrl('')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                xmlSpace="preserve"
              >
                <g
                  style={{
                    opacity: 1,
                    fill: "#fff",
                  }}
                >
                  <g
                    style={{
                      fill: "#fff",
                    }}
                  >
                    <path
                      style={{
                        fillRule: "evenodd",
                        clipRule: "evenodd",
                        fill: "#fff",
                      }}
                      d="m18.717 6.697-1.414-1.414L12 10.586 6.697 5.283 5.283 6.697 10.586 12l-5.303 5.303 1.414 1.414L12 13.414l5.303 5.303 1.414-1.414L13.414 12z"
                    />
                  </g>
                </g>
              </svg>
            </div>
            <div className='relatove w-full'>
              <video
                controls
                autoPlay
                style={{
                  maxWidth: 1024,
                  width: 'calc(100% - 100px)',
                  margin: 'auto',
                  marginTop: 100,
                  borderRadius: 16,
                }}
              >
                <source src={videoUrl} />
              </video>
            </div>
          </div>           
        </div>
    } */}
    </div> 
  )
}

export default React.memo(TouristDrive33);
