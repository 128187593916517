import React, { useState } from 'react';
import Map from '../../components/map';

const TouristMapOnHomepage = () => {
  const svgOnHover = (elem) => {
    if(elem.type === 'click' ){
      if(selectedRouteID === elem.target.id){
        setSelectedRouteID('')
      }else{
        setSelectedRouteID(elem.target.id)
      }
    }
    if(elem.target.id === 'polkobin-newcastle'){
      setSelectedRoute('wn')
    }
    else if(elem.target.id === 'ridge-wollombi'){
      setSelectedRoute('sw')
    }
    else if(elem.target.id === 'sydney-ridge'){
      setSelectedRoute('sw')
    }
    else if(elem.target.id === 'polkobin-ridge'){
      setSelectedRoute('sp')
    }
    else if(elem.target.id === 'wollombi-polkobin-direct'){
      setSelectedRoute('wp')
    }
    else if(elem.target.id === 'wollombi-broke'){
      setSelectedRoute('wb')
    }
    else{
      setSelectedRoute('')
    }
  }
  const textOnHover = (elem, id) => {
    if(elem.type === 'click' ){
      if(selectedRouteID === id){
        setSelectedRouteID('')
      }else{
        setSelectedRouteID(id)
      }
    }
    if(id === 'polkobin-newcastle'){
      setSelectedRoute('wn')
    }
    else if(id === 'ridge-wollombi'){
      setSelectedRoute('sw')
    }
    else if(id === 'sydney-ridge'){
      setSelectedRoute('sw')
    }
    else if(id === 'polkobin-ridge'){
      setSelectedRoute('sp')
    }
    else if(id === 'wollombi-polkobin-direct'){
      setSelectedRoute('wp')
    }
    else if(id === 'wollombi-broke'){
      setSelectedRoute('wb')
    }
    else{
      setSelectedRoute('')
    }
  }
  const onMouseLeaveText = (e, id) => {
    if(selectedRouteID !== id){
      setSelectedRoute('')
    }
  }
  const onMouseLeaveSVG = (e) => {
    if(selectedRouteID !== e.target.id){
      setSelectedRoute('')
    }
  }
  const [selectedRoute, setSelectedRoute] = useState('')
  const [selectedRouteID, setSelectedRouteID] = useState('')

  return (
    <div className='wp-react-app-main'>
      <div className="bg-black w-full h-auto bg-cover	flex items-center ">
        <div className='grid grid-cols-1 md:grid-cols-2 max-w-6xl mx-auto h-4/5'>
          <div className='col-span-1 bg-black/50 backdrop-opacity-50 py-4 md:py-0	px-6 lg:px-8 flex flex-col justify-center'>
            {/* <h2 className='font-risoless text-white mb-2 text-left'>Tourist Drive 33</h2> */}
            {/* <h2 className='font-amatic text-4xl text-white mb-2 text-left'>HUNTER VALLEY’S SCENIC ROUTE </h2> */}
            <h2 className='font-amatic text-4xl text-white mb-2 text-left uppercase'>When visiting the Hunter Valley take the Scenic Route</h2>
            <p className='mb-4 text-white text-left'>
            Originally linking Sydney to the fertile soils of the Hunter Valley, the <a href='https://www.visitwollombi.com.au/business/the-great-north-road-convict-trail/' className='tourist-33-link'>Great North Road</a> was convict built in the 1800’s, traversing stunning sandstone gorges, razorback ridges and towering passes all featured along Tourist Drive 33. Already a popular route for motorbike riders and day trippers, Tourist Drive 33 showcases some of the most stunning countryside in NSW and steps back in time as you meander through the convict stone walls, bridges and culverts still in use today.
            <br />
            <br />
            Stop in one of the many vibrant townships of Peats Ridge, Kulnura, Laguna, historic Wollombi and the wine regions of Broke Fordwich, Lovedale and Branxton that have loads on offer! Everything from horseriding, <a href='https://www.visitwollombi.com.au/business-category/local-produce/' className='tourist-33-link'>farmgate sales of seasonal fruits and vegetables</a>, quirky retail outlets, <a href='https://www.visitwollombi.com.au/business-category/dining/' className='tourist-33-link'>cafes and restaurants</a>, <a href='https://www.wollombitavern.com.au/' className='tourist-33-link'>country pubs</a>, <a href='https://www.visitwollombi.com.au/business-category/creative-arts-galleries/' className='tourist-33-link'>art galleries</a>, <a href='https://www.visitwollombi.com.au/business-category/local-wines/' className='tourist-33-link'>boutique cellar doors</a>, walking and cycling to a myriad of festivals and events on throughout the year.
            <br />
            <br />
            For those looking to stay and explore, an <a href='https://www.visitwollombi.com.au/hunter-valley-stay/' className='tourist-33-link'>abundance of accommodation choices are available</a> for <a href='https://www.visitwollombi.com.au/business-category/accommodation-couples-retreats/' className='tourist-33-link'>couples</a>, families and groups from self-contained, farmstay, <a href='https://www.visitwollombi.com.au/business-category/accommodation-guesthouse-bb/' className='tourist-33-link'>B&B to luxury guesthouses</a>. Come and discover what’s on offer on Tourist Drive 33!
            {/* Planning a visit to Hunter Valley Wine Country and hoping to take the road less travelled? Why not enjoy a scenic drive on Tourist Drive 33, along the Great North Road or Convict Trail, and through the beautiful NSW countryside. Tourist Drive 33 is the scenic gateway to the spectacular Hunter Valley stretching from the Peats Ridge exit on the M1 near Sydney, to Branxton in the heart of wine country along the historic Great North Road. */}
            </p>
            <a className='flex w-1/2 w-min-fit px-4 mt-3 hover:bg-red bg-transparent transition ease-in-out duration-300	 text-white border border-white text-sm h-8 font-light	rounded hover:border-red hover:text-white ' href='/tourist-drive-33/'>
            <span className='flex h-full w-full justify-center items-center'> {"EXPLORE TOURIST DRIVE 33"} </span>
            </a>
            <h4 className='text-sm mt-6' style={{ color: '#a01e22', fontSize: '0.875rem' }}>Route Information</h4>
            <div className='text-white gap-7 flex w-3/4 mt-4'> 
                
              <div className='w-1/2'>
                <p onClick={(e)=>{textOnHover(e, 'ridge-wollombi')}} onMouseOver={(e) =>{textOnHover(e, 'ridge-wollombi')}} onMouseLeave={(e) =>{onMouseLeaveText(e, 'ridge-wollombi')}} className={`${selectedRoute === 'sw' ? 'font-bold' : ''} cursor-pointer text-left`} > <b>Sydney</b> to Wollombi </p>
                <p onClick={(e)=>{textOnHover(e, 'polkobin-ridge')}} onMouseOver={(e) =>{textOnHover(e, 'polkobin-ridge')}} onMouseLeave={(e) =>{onMouseLeaveText(e, 'polkobin-ridge')}}  className={`${selectedRoute === 'sp' ? 'font-bold' : ''} cursor-pointer text-left` }> <b>Sydney</b> to Pokolbin </p>
                <p onClick={(e)=>{textOnHover(e, 'wollombi-polkobin-direct')}} onMouseOver={(e) =>{textOnHover(e, 'wollombi-polkobin-direct')}} onMouseLeave={(e) =>{onMouseLeaveText(e, 'wollombi-polkobin-direct')}}  className={`${selectedRoute === 'wp' ? 'font-bold' : ''} cursor-pointer text-left`}> <b>Wollombi</b> to Pokolbin </p>
                <p onClick={(e)=>{textOnHover(e, 'wollombi-broke')}} onMouseOver={(e) =>{textOnHover(e, 'wollombi-broke')}} onMouseLeave={(e) =>{onMouseLeaveText(e, 'wollombi-broke')}}  className={`${selectedRoute === 'wb' ? 'font-bold' : ''} cursor-pointer text-left`}> <b>Wollombi</b> to Broke </p>
                <p onClick={(e)=>{textOnHover(e, 'polkobin-newcastle')}} onMouseOver={(e) =>{textOnHover(e, 'polkobin-newcastle')}} onMouseLeave={(e) =>{onMouseLeaveText(e, 'polkobin-newcastle')}}  className={`${selectedRoute === 'wn' ? 'font-bold' : ''} cursor-pointer text-left`}> <b>Wollombi</b> to Newcastle </p>
              </div>
              <div className='w-1/2'>
                <p className={`${selectedRoute === 'sw' ? 'font-bold' : ''} text-left`}> 104 km (1:25 hrs)</p>
                <p  className={`${selectedRoute === 'sp' ? 'font-bold' : ''} text-left`}> 171 km (1:45 hrs)</p>
                <p  className={`${selectedRoute === 'wp' ? 'font-bold' : ''} text-left`}> 43 km (0:35 hrs)</p>
                <p  className={`${selectedRoute === 'wb' ? 'font-bold' : ''} text-left`}> 30 km (0:20 hrs)</p>
                <p  className={`${selectedRoute === 'wn' ? 'font-bold' : ''} text-left`}> 78km (1:00 hrs)</p>
              </div>
            </div>
          </div>
          <div className='col-span-1 py-4 md:py-0'>
            <Map
              selectedRoute={selectedRoute}
              onMouseLeaveSVG={onMouseLeaveSVG}
              svgOnHover={svgOnHover}
            />
          </div>
        </div>
      </div>
    </div>
    
  ) 
}

export default React.memo(TouristMapOnHomepage);
